import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CButton, CCol, CRow } from '@coreui/react-pro'
import { clearTerritory, setDescription, setName } from '../../../../../../redux/territory'
import { CForm, CFormInput, CFormTextarea, CSpinner } from '@coreui/react'
import { useEventBus } from '../../../../../../hooks/use-event-bus'

const TerritoryForm = ({
  territory,
  onComplete = async (variables) => void 0,
  onCancel = () => void 0,
}) => {
  const dispatch = useDispatch()
  // const territory = useSelector((state) => state.territoryReducer)
  const tName = useSelector((state) => {
    return state.territoryReducer?.name
  })

  const [loading, setLoading] = useState(false)

  const { emit } = useEventBus('toasts')

  // const [update, { loading: updateLoading }] = useMutation(UPDATE_TERRITORY, {
  //   onError(e) {
  //     emit({ type: 'error', message: e.message })
  //     setLoading(false)
  //   },
  //   onCompleted() {
  //     emit({ type: 'success', message: 'Territory updated!' })
  //   },
  // })

  const save = async () => {
    const t = { ...territory }
    if (!t.name || t.name === '') {
      emit({ message: 'Your territory needs a name!', type: 'error' })
      return
    }
    if (!t.codes || t.codes.length === 0) {
      emit({ message: 'You must have at least one area in your territory', type: 'error' })
      return
    }
    setLoading(true)
    try {
      await onComplete(t)
      dispatch(clearTerritory())
    } catch (e) {}
    setLoading(false)
  }

  return (
    <div className={'territory__form p-3'}>
      <CForm
        onSubmit={(e) => {
          e.preventDefault()
          save()
        }}
      >
        <CRow>
          <CCol>
            <CFormInput
              value={territory.name ?? ''}
              onChange={(e) => dispatch(setName(e.target.value))}
            />
          </CCol>
        </CRow>
        <CRow className={'mt-2 '}>
          <CCol>
            <CFormTextarea
              value={territory.description ?? ''}
              onChange={(e) => {
                dispatch(setDescription(e.target.value))
              }}
            />
          </CCol>
        </CRow>
        {/*<JSONPretty json={territory} />*/}
        <CRow className={'mt-2'}>
          <CCol className={'d-flex justify-content-between'}>
            <CButton color={'success'} type={'submit'} disabled={loading} className={'gap-2'}>
              {loading && (
                <>
                  <CSpinner size={'sm'} />{' '}
                </>
              )}
              Save
            </CButton>{' '}
            <CButton
              color={'danger'}
              variant={'outline'}
              onClick={() => dispatch(clearTerritory())}
            >
              Cancel
            </CButton>
          </CCol>
        </CRow>
      </CForm>
    </div>
  )
}

export default TerritoryForm
