import React, {useCallback, useContext, useEffect, useState} from 'react'
import {GeoJSONDataContext, GeoJSONDispatchContext} from '../../../../../contexts/geojson-data'
import { GeoJSON, useMapEvents } from 'react-leaflet'
import geoJSONBounds from '../../../tools/geojson-bounds'
import ChloroplethLayer from "../../DataAgg/Components/ChloroplethLayer";
import {useDispatch, useSelector} from "react-redux";
import {addCodes, editStatus, territorySlice, toggleCode} from "../../../../../redux/territory";
import Chloropleth from "./Chloropleth";
import {useEventBus} from "../../../../../hooks/use-event-bus";

const SelectionShapes = () => {
  const dispatch = useDispatch()
  const geoJSON = useSelector(({geojsonReducer}) => geojsonReducer.geoJSON)
  // const { geoJSON } = useContext(GeoJSONDataContext)
  const [finalGeoJSON, setFinalGeoJSON] = useState(undefined)
  const [bounds, setBounds] = useState({})
  const [activeID, setActiveID] = useState("")

  const isEditing = useSelector(({territoryReducer}) => territoryReducer.edit)
  const selectedAreaIds = useSelector(({territoryReducer}) => territoryReducer.codes)

  useEventBus("map-actions", ({detail: {action, value}}) => {
    if(action === "setActiveID") {
      setActiveID(value)
    }
  })

  const map = useMapEvents({
    moveend(e) {
      setBounds(e.target.getBounds())
    },
  })

  useEffect(() => {
    if (map) {
      setBounds(map.getBounds())
    }
  }, [map])

  useEffect(() => {
    if (geoJSON && geoJSON?.postcode_district && bounds && Object.keys(bounds).length > 0) {
      setFinalGeoJSON(geoJSON.postcode_district)
    }
  }, [geoJSON, bounds, isEditing, selectedAreaIds])

  const territoryClick = (id) => {
    if(isEditing) {
      dispatch(toggleCode(id))
    }
  }

  if (!finalGeoJSON) {
    return <></>
  }

  return (
    <>
      <Chloropleth
        geoJSON={finalGeoJSON}
        selectedAreaIDs={selectedAreaIds}
        activeID={activeID}
        onClick={(id) => territoryClick(id)}
        freeze={isEditing === false}
      />
      {/*<ChloroplethLayer*/}
      {/*  regions={[0]}*/}
      {/*  geoJSON={finalGeoJSON}*/}
      {/*  selectedTerritoryID={activeId}*/}
      {/*  range={[0,0]}*/}
      {/*  calcs={[0,0,0]}*/}
      {/*  selectedTerritories={selectedAreaIds}*/}
      {/*  onClick={(id) => void territoryClick(id)}*/}
      {/*/>*/}
    </>
  )
}

export default SelectionShapes
