import React, { useContext, useEffect, useState } from 'react'
import { MapContainer, Pane, TileLayer, ZoomControl } from 'react-leaflet'
import MapControls from './Components/MapControls'
import { GeoJSONDataContext, GeoJSONDispatchContext } from '../../../../contexts/geojson-data'
import TerritoryShapes from './Components/TerritoryShapes'
import SelectionShapes from './Components/SelectionShapes'
import InPortal from '../../../UI/InPortal'
import TerritoryTabs from './Components/TerritoryTabs'
import TerritoryForm from './Components/TerritoryForm'
import { setTerritoryJSONCB } from '../../../../graphql/policies/search'
import { fiveDigitRand } from '../../../../tools/random'
import { useEventBus } from '../../../../hooks/use-event-bus'
import { useDispatch } from 'react-redux'
import { collapseMenu, expandMenu } from '../../../../redux/ui-state'
import {loadGeoJSONByType, rerollCacheBuster} from "../../../../redux/geojson";
import {clearTerritory} from "../../../../redux/territory";

const TerritoryBuilder = () => {
  const key = window._env_.MAPTILES_KEY
  const dispatch = useDispatch()
  const { loadGeoJSON, loading } = useContext(GeoJSONDataContext)
  const geojsonDispatch = useContext(GeoJSONDispatchContext)
  const { emit } = useEventBus('territory-refresh')
  const [activeID, setActiveID] = useState("")
  useEventBus("map-actions", ({detail: {action, value}}) => {
    if(action === "setActiveID") {
      setActiveID(value)
    }
  })

  const [style, setStyle] = useState({})

  useEffect(() => {
    // loadGeoJSON('postcode_district').then(() => {
    //   loadGeoJSON('user_territory')
    // })
    dispatch(loadGeoJSONByType("postcode_district"))
    dispatch(loadGeoJSONByType("user_territory"))

    geojsonDispatch('SET_LIVE_GROUP_ID', '')
    dispatch(collapseMenu())
    dispatch(clearTerritory())
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 300)
    return () => {
      dispatch(expandMenu())
      dispatch(clearTerritory())
    }
  }, [])

  // const updateMapSize = (height) => {
  //   setStyle({ '--lower-tabs-height': `${height}px` })
  //   setTimeout(() => {
  //     window.dispatchEvent(new Event('resize'))
  //   }, 100)
  // }

  return (
    <>
      <div className={'map-page__body'} style={style}>
        <InPortal portal={'map-tools-header'}>
          <TerritoryTabs setStyle={setStyle} />
        </InPortal>

        <MapContainer
          doubleClickZoom={false}
          style={{ margin: '0', position: 'relative', width: '100%', height: '100%' }}
          zoom={8}
          center={[54.093622, -2.892616]}
          zoomControl={false}
        >
          <ZoomControl position={'bottomright'} />
          <TileLayer
            attribution={
              '\u003ca href="https://www.maptiler.com/copyright/" target="_blank"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href="https://www.openstreetmap.org/copyright" target="_blank"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e'
            }
            ext={'png'}
            url={`https://api.maptiler.com/maps/dataviz/{z}/{x}/{y}.png?key=${key}`}
            zIndex={300}
            crossOrigin
          />
          <MapControls />
          <Pane name={'area-shapes'} style={{ zIndex: '500' }}>
            <SelectionShapes />
          </Pane>
          <Pane name={"selected-areas"} style={{zIndex: "550"}} />
          <Pane name={'territory-shapes'} style={{ zIndex: '600' }}>
            <TerritoryShapes activeID={activeID} />
          </Pane>
          <Pane name={"popups"} style={{zIndex: '700'}} />
        </MapContainer>
      </div>
    </>
  )
}

export default TerritoryBuilder
