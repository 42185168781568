import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fiveDigitRand} from "../tools/random";
import axios from "axios";
import {getAuthToken} from "../auth";

const initialState = {
  activeId: "",
  territoryCacheBuster: fiveDigitRand(),
  geoJSON: {
    empty: {
      features: []
    }
  },
  loading: false,
}

export const loadGeoJSONByType = createAsyncThunk("geojson/loadGeoJSONByType", async (type = "", {getState}) => {
  let url = ""
  if(type === "user_territory") {
    const {geojsonReducer: {territoryCacheBuster}} = getState()
    url = `/geojson/user_territory?${territoryCacheBuster}`
  } else {
    url = `/geojson/gb/${type}`
  }

  try {
    const res = await axios.get(
      url,
      {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`
        },
      }
    )
    return {
      type,
      geoJSON: res.data
    }
  } catch (e) {
    return {
      type,
      geoJSON: {empty: {features: [{type: "Feature"}]}}
    }
  }
})

export const geojsonSlice = createSlice({
  name: "geojson",
  initialState,
  reducers: {
    setActive: (state, {payload}) => {
      state.activeId = payload
    },
    clearActive: (state, {payload}) => {
      if(!payload) {
        state.activeId = ""
      } else {
        if(state.activeId === payload) {
          state.activeId = ""
        }
      }
    },
    setGeoJSON: (state, {payload}) => {
      const {type, geoJSON} = payload
      state.geoJSON[type] = geoJSON
    },
    rerollCacheBuster: (state) => {
      state.territoryCacheBuster = fiveDigitRand()
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loadGeoJSONByType.fulfilled, (state, action) => {
      const {payload: {type, geoJSON}} = action
      state.geoJSON[type] = geoJSON
    })
  }
})

export const {
  setActive,
  clearActive,
  setGeoJSON,
  rerollCacheBuster,
} = geojsonSlice.actions





export default geojsonSlice.reducer
