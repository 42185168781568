import {createSelector, createSlice} from '@reduxjs/toolkit'

const initialState = {
  id: '',
  name: 'New Territory',
  description: '',
  codes: [],
  edit: false,
}

export const territorySlice = createSlice({
  name: 'territory',
  initialState,
  reducers: {
    setTerritory: (state, {payload}) => {

      state = {
        ...state,
        ...payload,
        edit: true
      }
      return state
    },
    setId: (state, value) => {
      state.id = value.payload
    },
    setName: (state, value) => {
      state.name = value.payload
    },
    toggleCode: (state, {payload}) => {
      const {codes} = state
      const idx = codes.indexOf(payload)
      if(idx !== -1) {
        codes.splice(idx, 1)
      } else {
        codes.push(payload)
      }
    },
    addCodes: (state, {payload}) => {
      const {codes} = state
      let add = []
      if(Array.isArray(payload)) {
        add = payload
      } else {
        add = [payload]
      }
      state.codes = Array.from(new Set([
        ...codes,
        ...add,
      ]))
    },
    startEdit: (state) => {
      state.edit = true
    },
    stopEdit: (state) => {
      state.edit = false
    },
    removeCode: (state, value) => {
      let {codes} = state
      if(codes.indexOf(value.payload) !== -1) {
        codes.splice(codes.indexOf(value.payload), 1)
        state.codes = [...codes]
      }

    },
    setDescription: (state, {payload}) => {
      state.description = payload
    },
    clearCodes: (state) => {
      state.codes = []
    },
    clearTerritory: (state, val) => {

      return {...initialState, edit: false}
    },
  },
})

export const {
  setName,
  setDescription,
  addCodes,
  toggleCode,
  removeCode,
  clearCodes,
  setId,
  setTerritory,
  clearTerritory,
  startEdit,
  stopEdit,
} = territorySlice.actions

const isEditing = ({territoryReducer}) => territoryReducer.isEditing
const selectedAreaIds = ({territoryReducer}) => territoryReducer.selectedAreaIds

export const editStatus = createSelector([isEditing, selectedAreaIds], (isEditing, selectedAreaIds) => {
  return {
    isEditing,
    selectedAreaIds,
  }
})

export default territorySlice.reducer
